/** @jsx jsx */
import { jsx } from 'theme-ui'
import { sortStatus, tableColumns } from '../../../common/utilities'
import { numberCell } from '../../../common/utilities/tableCellUtils'
import * as style from './style'

const status = [
  'NEW',
  'PARTIALLY_FILLED',
  'FILLED_INCOMPLETE',
  'FILLED',
  'CANCELLED',
  'PENDING_CANCEL',
  'REJECTED',
  'PENDING_NEW',
  'PENDING_REPLACE',
]

const defaultSorted = [{ id: 'formatDate', desc: true }]

const getDefaultFilters = () => ({
  rows: [],
  columns: [
    { accessor: 'formatDate', isVisible: true },
    { accessor: 'orderStatus', isVisible: true },
    { accessor: 'execStatus', isVisible: true },
    { accessor: 'clientOrderId', isVisible: true },
    { accessor: 'marketOrderId', isVisible: false },
    { accessor: 'trader', isVisible: false },
    { accessor: 'formatQuantity', isVisible: true },
    { accessor: 'formatCumQuantity', isVisible: true },
    { accessor: 'formatLastQuantity', isVisible: true },
    { accessor: 'formatPendingQuantity', isVisible: true },
    { accessor: 'limitPrice', isVisible: true },
    { accessor: 'stopPrice', isVisible: false },
    { accessor: 'executionPrice', isVisible: true },
    { accessor: 'formatAveragePrice', isVisible: true },
    { accessor: 'currency', isVisible: true },
    { accessor: 'formatBuyQuantity', isVisible: true },
    { accessor: 'buyCurrency', isVisible: true },
    { accessor: 'formatSellQuantity', isVisible: true },
    { accessor: 'sellCurrency', isVisible: true },
    { accessor: 'tradeId', isVisible: true },
    { accessor: 'ork', isVisible: false },
    { accessor: 'formatExpirationDate', isVisible: false },
    { accessor: 'reason', isVisible: false },
    { accessor: 'text', isVisible: true },
    { accessor: 'externalCustomerId', isVisible: true },
  ],
})

const columnsHead = t => {
  /*
   *  Cell format for the Status column
   */
  const statusCell = status => (
    <div
      sx={{
        ...style.statusContainer,
        ...(style[`${status.replace('_', '').toLowerCase()}Status`] || style.defaultStatus),
      }}
    >
      <span sx={style.statusText}>{t(status)}</span>
    </div>
  )

  const c = tableColumns(t)

  const statusProps = {
    options: status,
    sortMethod: sortStatus,
    width: 105,
    Cell: ({ value }) => statusCell(value) || '',
  }

  const commonProps = {
    sortable: false,
  }
  const smallColumn = { style: { fontSize: 'small' } }

  return [
    c.getDateColumn({
      header: 'timeLocal',
      accessor: 'formatDate',
      ...commonProps,
      ...smallColumn,
      width: 150,
    }),
    c.getSelectColumn({
      header: 'status',
      accessor: 'orderStatus',
      ...commonProps,
      ...statusProps,
      ...smallColumn,
    }),
    c.getSelectColumn({
      header: 'execStatus',
      ...commonProps,
      ...smallColumn,
    }),
    c.getColumn({ accessor: 'clientOrderId', ...commonProps }),
    c.getColumn({ accessor: 'marketOrderId', ...commonProps }),
    c.getColumn({ accessor: 'trader', ...commonProps }),
    c.getFromToIntColumn({
      header: 'quantity',
      accessor: 'formatQuantity',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getFromToIntColumn({
      header: 'lastqty',
      accessor: 'formatLastQuantity',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getFromToIntColumn({
      header: 'cumQuantity',
      accessor: 'formatCumQuantity',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getFromToFloatColumn({
      header: 'pendingQty',
      accessor: 'formatPendingQuantity',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getFromToFloatColumn({ header: 'limitPrice', Cell: numberCell, ...commonProps }),
    c.getFromToFloatColumn({ header: 'stopPrice', Cell: numberCell, ...commonProps }),
    c.getFromToFloatColumn({
      header: 'executionPrice',
      accessor: 'executionPrice',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getFromToFloatColumn({
      header: 'avgPrice',
      accessor: 'formatAveragePrice',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getColumn({ accessor: 'currency', ...commonProps }),
    c.getFromToFloatColumn({
      header: 'buyQuantity',
      accessor: 'formatBuyQuantity',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getColumn({ accessor: 'buyCurrency', ...commonProps }),
    c.getFromToFloatColumn({
      header: 'sellQuantity',
      accessor: 'formatSellQuantity',
      Cell: numberCell,
      ...commonProps,
    }),
    c.getColumn({ accessor: 'sellCurrency', ...commonProps }),
    c.getColumn({ accessor: 'tradeId', ...commonProps }),
    c.getColumn({ accessor: 'ork', ...commonProps, ...smallColumn }),
    c.getDateColumn({
      header: 'expirationDate',
      accessor: 'formatExpirationDate',
      ...commonProps,
      ...smallColumn,
      width: 150,
    }),
    c.getColumn({ accessor: 'reason', ...commonProps }),
    c.getColumn({ accessor: 'text', ...commonProps }),
    c.getColumn({ accessor: 'externalCustomerId', ...commonProps }),
  ]
}

export { columnsHead, defaultSorted, getDefaultFilters }
