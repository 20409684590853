/** @jsx jsx */
import { jsx, useColorMode } from 'theme-ui'

import Keycloak from 'keycloak-js'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'wouter'
import React, { useEffect, useState, useContext } from 'react'
import { PermissionsContext } from '../containers/PermissionsProvider'

import { ROOT, LEGAL_NOTICE, COOKIE_POLICY, PRIVACY_POLICY } from '../common/routes'

import WorkspacesGrid from './Workspace'
import CookiePolicy from '../pages/CookiePolicy'
import LegalNotice from '../pages/LegalNotice'
import PrivacyPolicy from '../pages/PrivacyPolicy'

import Loading from '../components/Loading'
// import { socketInit } from '../actions/socket' // TODO: RFQ
import config from '../common/config'
import { fetchWorkspace } from '../actions/workspace'
import { fetchInstruments } from '../actions/instruments'
import { fetchMarkets } from '../actions/markets'
import { fetchAccounts } from '../actions/accounts'
import { fetchEnablements } from '../actions/enablements'
import { connectToOrdersSocket } from '../actions/orders'
import { connectToMarketSocket } from '../actions/marketData'
import { connectToPositionsSocket, setPositionsTabs } from '../actions/positions'

const getInitialData = keycloak => dispatch =>
  Promise.all([
    dispatch(fetchWorkspace(keycloak.token)),
    dispatch(fetchInstruments(keycloak.token)),
    dispatch(fetchMarkets(keycloak.token)),
    dispatch(fetchAccounts(keycloak)),
    dispatch(fetchEnablements(keycloak)),
    dispatch(connectToMarketSocket(keycloak.token)),
    dispatch(connectToOrdersSocket(keycloak.token)),
    dispatch(connectToPositionsSocket(keycloak.token)),
  ])

const App = ({
  currencySocketConnected,
  getInitialData,
  instrumentsFetched,
  accountsFetched,
  orderSocketConnected,
  positionsSocketConnected,
  // socketConnected, // TODO: RFQ
  // socketInit, // TODO: RFQ
  workspaceFetched,
  setPositionsTabs,
}) => {
  const [keycloak, setKeycloak] = useState(null)

  const { setRoles } = useContext(PermissionsContext)

  const [theme, setTheme] = useColorMode()

  const [staticPage, setStaticPage] = useState(false)

  const getThemeFromClients = clients => {
    if (clients) {
      for (let client of clients) {
        if (client?.theme) {
          return client.theme
        }
      }
    }
    return null
  }

  useEffect(() => {
    switch (window.location.pathname) {
      case PRIVACY_POLICY:
      case LEGAL_NOTICE:
      case COOKIE_POLICY:
        setStaticPage(true)
        break
      default:
        const keycloak = new Keycloak(config().keycloak)
        keycloak
          .init({ onLoad: 'login-required', checkLoginIframe: false })
          .success(authenticated => {
            if (authenticated) {
              setRoles(keycloak.tokenParsed.realm_access.roles)
              // socketInit(keycloak.token)  // TODO: RFQ
              setKeycloak(keycloak)
              getInitialData(keycloak)

              if (keycloak.tokenParsed?.theme) {
                setTheme(keycloak.tokenParsed.theme)
              } else if (getThemeFromClients(keycloak.tokenParsed?.clients)) {
                setTheme(getThemeFromClients(keycloak.tokenParsed?.clients))
              } else if (keycloak.tokenParsed.member?.theme) {
                setTheme(keycloak.tokenParsed.member.theme)
              } else {
                setTheme('dark')
              }
            }
          })
          .error(console.error)

        setInterval(() => {
          //console.debug('Launching token check');
          keycloak.updateToken(70).success((refreshed) => {
            if (refreshed) {
              console.debug('Token refreshed');
            } else {
              //console.debug('Token not refreshed, valid for '
              //  + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
              //console.debug('Refresh token valid for '
              //  + Math.round(keycloak.refreshTokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
          }).error(() => {
            console.error('Failed to refresh token');
          });

        }, 30000)
    }
  }, [])

  useEffect(() => {
    setPositionsTabs()
  }, [workspaceFetched])

  const isLoading =
    (keycloak &&
      workspaceFetched &&
      // socketConnected && // TODO: RFQ
      instrumentsFetched &&
      accountsFetched &&
      currencySocketConnected &&
      orderSocketConnected &&
      positionsSocketConnected) ||
    staticPage

  return isLoading ? (
    <Switch>
      <Route path={ROOT}>{props => <WorkspacesGrid {...props} keycloak={keycloak} />}</Route>
      <Route path={COOKIE_POLICY}>{<CookiePolicy />}</Route>
      <Route path={LEGAL_NOTICE}>{<LegalNotice />}</Route>
      <Route path={PRIVACY_POLICY}>{<PrivacyPolicy />}</Route>
      <Redirect to={ROOT} />
    </Switch>
  ) : (
    <Loading />
  )
}

const mapStateToProps = ({
  workspace: { fetched: workspaceFetched },
  socket: { socketConnected },
  instruments: { fetched: instrumentsFetched },
  accounts: { fetched: accountsFetched },
  marketData: { connected: currencySocketConnected },
  order: { connected: orderSocketConnected },
  positions: { connected: positionsSocketConnected },
}) => ({
  workspaceFetched,
  // socketConnected, // TODO: RFQ
  instrumentsFetched,
  accountsFetched,
  currencySocketConnected,
  orderSocketConnected,
  positionsSocketConnected,
})
const mapDispatchToProps = { getInitialData, setPositionsTabs /*socketInit TODO: RFQ */ }

export default connect(mapStateToProps, mapDispatchToProps)(App)
