/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { sortStatus, tableColumns } from '../../common/utilities'
import * as style from './style'
import { numberCell } from '../../common/utilities/tableCellUtils'
import { Search, Edit, Cancel } from '@material-ui/icons'
import { subscribeSymbolStatic } from '../../actions/marketData'

const sides = ['BUY', 'SELL']
const status = [
  'NEW',
  'PARTIALLY_FILLED',
  'FILLED_INCOMPLETE',
  'FILLED',
  'CANCELLED',
  'PENDING_CANCEL',
  'REJECTED',
  'PENDING_NEW',
  'PENDING_REPLACE',
]
const orderTypes = ['LIMIT', 'MARKET', 'STOP_LIMIT', 'APPLICATION']
const tifs = ['DAY', 'GTD', 'IOC', 'FOK']
const capacities = ['DEAL', 'MATCH', 'ANY_OTHER']
const execType = [
  'NEW',
  'TRADE',
  'CANCELLED',
  'EXPIRED',
  'PENDING_NEW',
  'REJECTED',
  'PENDING_CANCEL',
  'PENDING_REPLACE',
  'REPLACED',
  'ORDER_STATUS',
  'RESTATED',
  'TRADE_CANCEL',
  'TRADE_CORRECT',
  'TRIGGERED',
]
const defaultSorted = [{ id: 'formatDate', desc: true }]

/**
 * Check if provided order is an application
 */
const isApplicationOrder = order => order.orderType === 'APPLICATION'

/*
 * Statuses for which editing will not be enabled
 */
const orderDisabledEditStatuses = [
  'FILLED',
  'FILLED_INCOMPLETE',
  'CANCELLED',
  'REJECTED',
  'PENDING_NEW',
  'PENDING_REPLACE',
  'PENDING_CANCEL',
]

const columnsHead = t => {
  /*
   *  Cell format for the Status column
   */
  const statusCell = ({ value }) =>
    value ? (
      <div
        sx={{
          ...style.statusContainer,
          ...(style[`${value.replace('_', '').toLowerCase()}Status`] || style.defaultStatus),
        }}
      >
        <span sx={style.statusText}>{t(value)}</span>
      </div>
    ) : (
      ''
    )

  /*
   * Returns if an order is editable
   */
  const isOrderEditable = order =>
    order && !orderDisabledEditStatuses.includes(order.orderStatus) && !isApplicationOrder(order)

  /* The actions cell formatted */
  const actionsCell = ({ original: order }) => {
    const disabled = !isOrderEditable(order)
    return order
      ? [
        <Search
          sx={{ cursor: 'pointer' }}
          key={1}
          onClick={() => {
            subscribeSymbolStatic({
              instrument: order.instrument,
              subscriptionType: 'tick',
              account: order.accountId,
            })
            order.handleModal(
              { ...order, history: [order, order, order, order, order] },
              true,
              order.orderStatus
            )
          }}
        />,
        <Edit
          key={2}
          sx={{ ...(disabled ? style.disabled : { cursor: 'pointer' }) }}
          onClick={() => {
            if (!disabled) {
              subscribeSymbolStatic({
                instrument: order.instrument,
                subscriptionType: 'tick',
                account: order.accountId,
              })
              order.handleEditModal(order, false, order.orderStatus)
            }
          }}
        />,
        <Cancel
          key={3}
          sx={{ ...(disabled ? style.disabled : { cursor: 'pointer' }) }}
          onClick={() => !disabled && order.handleCancel(order)}
        />,
      ]
      : []
  }

  const c = tableColumns(t)

  return [
    c.getSelectColumn({
      header: 'status',
      accessor: 'orderStatus',
      csvHeader: 'status',
      options: status,
      sortMethod: sortStatus,
      width: 170,
      Cell: statusCell,
    }),
    c.getColumn({
      header: 'actions',
      skipCsv: true,
      sortable: false,
      filterable: false,
      Cell: actionsCell,
    }),
    c.getColumn({ accessor: 'orderId', csvHeader: 'orderId', width: 245 }),
    c.getDateColumn({ header: 'dateLocal', csvHeader: 'date', accessor: 'formatDate', width: 220 }),
    c.getColumn({ header: 'account', csvHeader: 'account', accessor: 'formatAccount' }),
    c.getColumn({ header: 'instrument', csvHeader: 'instrument', accessor: 'symbol' }),
    c.getSelectColumn({ header: 'side', csvHeader: 'side', options: sides }),
    c.getFromToIntColumn({
      header: 'orderQuantity',
      accessor: 'formatQuantity',
      csvHeader: 'quantity',
      Cell: numberCell,
    }),
    c.getFromToIntColumn({
      header: 'cumQuantity',
      accessor: 'formatCumQuantity',
      csvHeader: 'cumQuantity',
      Cell: numberCell,
    }),
    c.getFromToIntColumn({
      header: 'remainingQuantity',
      accessor: 'formatRemainingQuantity',
      csvHeader: 'remainingQuantity',
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'pendingQuantity',
      accessor: 'formatPendingQuantity',
      csvHeader: 'pendingQuantity',
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'limitPrice',
      accessor: 'formatPrice',
      csvHeader: 'price',
      Cell: numberCell,
    }),
    c.getFromToFloatColumn({
      header: 'averagePrice',
      accessor: 'formatAveragePrice',
      csvHeader: 'filledPrice',
      Cell: numberCell,
    }),
    c.getColumn({ accessor: 'currency', csvHeader: 'currency' }),
    c.getFromToIntColumn({
      header: 'buyQuantity',
      accessor: 'formatBuyQuantity',
      csvHeader: 'buyQuantity',
      Cell: numberCell,
    }),
    c.getColumn({ accessor: 'buyCurrency', csvHeader: 'buyCurrency' }),
    c.getFromToIntColumn({
      header: 'sellQuantity',
      accessor: 'formatSellQuantity',
      csvHeader: 'sellQuantity',
      Cell: numberCell,
    }),
    c.getColumn({ accessor: 'sellCurrency', csvHeader: 'sellCurrency' }),
    c.getSelectColumn({
      header: 'type',
      accessor: 'orderType',
      csvHeader: 'type',
      options: orderTypes,
    }),
    c.getSelectColumn({ header: 'expiry', accessor: 'tif', csvHeader: 'tif', options: tifs }),
    c.getSelectColumn({
      header: 'tradingCapacity',
      csvHeader: 'tradingCapacity',
      options: capacities,
    }),
    c.getColumn({ accessor: 'text', csvHeader: 'text' }),
    c.getColumn({ accessor: 'orderRejectText', csvHeader: 'orderRejectText' }),
    c.getDateColumn({
      header: 'lastUpdateDate',
      accessor: 'formatUpdateDate',
      csvHeader: 'updateDate',
      width: 285,
    }),
    c.getColumn({ accessor: 'clientOrderId', csvHeader: 'clientOrderId' }),
    c.getColumn({ accessor: 'orderCancelText', csvHeader: 'orderCancelText' }),
    c.getFromToIntColumn({ header: 'clientId', csvHeader: 'clientId' }),
    c.getFromToIntColumn({ header: 'decisorId', csvHeader: 'decisorId' }),
    c.getFromToIntColumn({ header: 'executorId', csvHeader: 'executorId' }),
    c.getFromToFloatColumn({ header: 'stopPrice', csvHeader: 'stopPrice', Cell: numberCell }),
    c.getDateColumn({
      header: 'expirationDate',
      accessor: 'formatExpirationDate',
      csvHeader: 'expirationDate',
      width: 220,
    }),
    c.getSelectColumn({
      header: 'execStatus',
      csvHeader: 'execStatus',
      Cell: ({ value }) => (value ? t(value) : ''),
      width: 220,
      options: execType,
    }),
    c.getColumn({ header: 'exchangeId', csvHeader: 'exchangeId' }),
    c.getColumn({ header: 'externalCustomerId', csvHeader: 'externalCustomerId' }),

  ]
}

export { columnsHead, defaultSorted }
