export default {
  account: 'Trades Account',
  accountCollateral: 'Trades Account collateral consumption (estimated)',
  accountError:
    'A suitable account has not been selected for an operation involving this type of product.',
  actions: 'Actions',
  add: 'Add',
  addOrderList: 'Order list',
  addRfqTab: 'RFQ panel',
  addDepthSplitQuote: 'Split Quote Market Depth',
  addSplitQuote: 'Split quote',
  addTableTab: 'Table panel',
  addAccountSummaryList: 'My accounts summary',
  addAccountPositionList: 'Selected account position',
  addMarketDepth: 'Market depth',
  addPositionList: 'Full positions list',
  addTradeList: 'Trade list',
  addQuoteList: 'Quote list',
  addPriceList: 'Price list',
  addTrade: 'Order entry panel',
  addAccountGroup: 'Accounts group',
  ALL: 'All',
  allTypes: 'All types',
  amount: 'Volume',
  ANY_OTHER: 'AOTC',
  APPLICATION: 'Cross trade',
  authError: 'Incorrect username or password',
  averagePrice: 'Cumulative Average price',
  BMEFX: 'BME FX',
  BUY: 'Buy',
  BUYACTION: 'Buy',
  realizedProfit: 'Realized P&L',
  realizedProfitEUR: 'Realized P&L (EUR)',
  cancel: 'Cancel',
  CANCELLED: 'Cancelled',
  cancelOrder: `Cancel order`,
  CLASSIC: 'Classic future',
  clientId: 'Client',
  clientOrderId: 'Client order ID',
  marketOrderId: 'Market order ID',
  close: 'Close',
  columnSelection: 'Column selection',
  confirm: 'Confirm',
  confirmAction: 'Confirm action',
  confirmOrder: 'Confirm order',
  connectionStatus: 'Connection status',
  cookiePolicy: 'Cookie policy',
  createAccountGroup: 'Create account group',
  createRFQ: 'Create RFQ',
  currency: 'Currency',
  dark: 'Dark',
  dateLocal: 'Input date (local)',
  timeLocal: 'Time (local)',
  DAY: 'DAY',
  DEAL: 'DEAL',
  decisorId: 'Decisor',
  deferralVariationMargin: 'Deferral VM',
  deferralVariationMarginEUR: 'Deferral VM (EUR)',
  delete: 'Delete',
  dontChange: "Don't change",
  dontShowAgain: "Don't show this message again",
  dragText: '<br><br>(Drag to location)',
  endRFQ: 'End RFQ',
  en: 'English',
  es: 'Spanish',
  error: 'Error',
  EUR: 'EUR',
  exchange: 'Exchange',
  exchangeId: 'Exchange ID',
  execStatus: 'Event status',
  executorId: 'Executor',
  expirationDate: 'Expiration date',
  EXPIRED: 'Expired',
  expiry: 'Expiry',
  FILLED: 'Filled',
  FILLED_INCOMPLETE: 'Filled incompl.',
  filledPrice: 'Filled price',
  FOK: 'FOK',
  GTD: 'GTD',
  hideTables: 'Hide tables',
  in: 'in',
  instrument: 'Instrument',
  interface: 'Interface',
  interfaceSettings: 'Interface settings',
  IOC: 'IOC',
  intradayCollateral: 'Intraday collateral consumption (estimated)',
  keep: 'Keep',
  keycloak: 'keycloak',
  lang: 'EN',
  language: 'Language',
  lastUpdateDate: 'Last update date',
  legalNotice: 'Legal notice',
  light: 'Light',
  LIMIT: 'Limit',
  login: 'Login',
  logout: 'Logout',
  MARKET: 'Market',
  MATCH: 'MTCH',
  maxCollateral: 'Max collateral allowed',
  modalDeleteAccountGroup: name => `You are going to delete the account group ${name}`,
  modalTitleDeleteAccount: name => `Delete ${name}`,
  modifyOrder: 'Modify order',
  notModify: 'Don´t modify',
  more: 'More',
  netPosition: 'Net position',
  netPositionValue: 'Net position value',
  NEW: 'New',
  newTab: 'New tab',
  newWorkspace: 'New workspace',
  noAvailable: 'Not available',
  noCancel: `Don't cancel`,
  noPlace: `Don't place`,
  ORDER_STATUS: 'Order status',
  orderDetails: 'Order details',
  orderedPrice: 'Ordered price',
  orderId: 'Order No',
  orderQuantity: 'Order volume',
  orders: 'Orders',
  orderListLabel: 'Orders',
  orderCancelText: 'Order cancel text',
  orderRejectText: 'Order reject text',
  reason: 'Reason',
  ork: 'ORK',
  orkError: 'Invalid ORK encoding - order will not be sent',
  pair: 'Pair',
  PARTIALLY_FILLED: 'Partially filled',
  password: 'Password',
  passwordVisibility: 'Toggle password visibility',
  PENDING_CANCEL: 'Pending cancel',
  PENDING_NEW: 'Pending new',
  pendingQuantity: 'Pending volume',
  pendingQty: 'Pending vol',
  PENDING_REPLACE: 'Pending replace',
  PENDING: 'Pending',
  placeOrder: 'Place order',
  preferences: 'Preferences',
  price: 'Price',
  privacyPolicy: 'Privacy policy',
  limitPrice: 'Limit price',
  shortLimitPrice: 'LMT price',
  stopPrice: 'Stop price',
  executionPrice: 'Execution price',
  avgPrice: 'Cumulative Average price',
  shortStopPrice: 'STP price',
  productSubType: 'Product subtype',
  positionListLabel: 'Full positions list',
  marketDepthLabel: 'Market depth',
  accountPositionListLabel: 'Selected account position',
  pyl: 'P&L',
  quantity: 'Volume',
  QTY: 'VOL',
  qty: 'Vol',
  lastqty: 'Event vol',
  cumQty: 'Executed vol',
  cumQuantity: 'Executed volume',
  realizedPL: 'Realized P&L',
  REJECTED: 'Rejected',
  remainingQuantity: 'Remaining volume',
  remainingCollateral: 'Remaining collateral (estimated)',
  REPLACE_REJECTED: 'Replace rejected',
  REPLACED: 'Replaced',
  RESTATED: 'Restated',
  rfq: 'RFQ',
  rfqList: 'RFQ list',
  ROLLING_SPOT: 'xRolling FX',
  FXSPOT: 'FX Spot',
  BMEFXSPOT: 'BMEFX',
  null: '',
  save: 'Save',
  search: 'Search',
  selectAccountGroupsName: 'Select account group',
  selectInstrument: 'Select instrument',
  SELL: 'Sell',
  SELLACTION: 'Sell',
  showConfirmTransaction: 'Show message to confirm transaction',
  showTables: 'Show tables',
  side: 'Side',
  signIn: 'Sign in',
  splitQuote: 'Split Quote',
  splitQuoteLabel: 'Split Quote',
  depthSplitQuoteLabel: 'Split Quote Market Depth',
  SPOT: 'FX Spot',
  status: 'Status',
  STOP_LIMIT: 'Stop Limit',
  accountSummaryListLabel: 'My accounts summary',
  tenor: 'Tenor',
  text: 'Event Text',
  theme: 'Switch theme',
  title: 'BME FX',
  TRADE: 'Trade',
  TRADE_CANCEL: 'Trade cancel',
  TRADE_CORRECT: 'Trade correct',
  trade: 'Trade',
  tradeId: 'Trade ID',
  tradeLabel: 'Order entry',
  tradeLocal: 'Trade time (Local)',
  trader: 'Trader',
  trades: 'Trades',
  TRIGGERED: 'Triggered',
  quoteListLabel: 'Quotes', //flexlayout tab name
  tradeListLabel: 'Trades',
  priceListLabel: 'Prices',
  tradingCapacity: 'Trading Capacity',
  tradingSettings: 'Trading settings',
  type: 'Type',
  unrealizedPL: 'Unrealized P&L',
  unrealizedPLE: 'Unrealized P&L (EUR)',
  username: 'Username',
  userSettingsTitle: 'User settings',
  dateFormat: 'dd/MM/yy',
  masterOrderId: 'Master Order ID',
  orderHistory: 'Order History',
  //ORK Validations
  clientIdRange: 'The client ID must be 0, 1, 2 or an integer >= 100 and <= 4,294,967,295.',
  decisorIdZeroOrInteger:
    'Decisor ID value ​​can be zero (0) or integers between >= 100 and <= 4,294,967,295 (2 ^ 32-1).',
  executorIdThreeOrInteger:
    'Executor ID value ​​can be three (3) or integers between >= 100 and <= 4,294,967,295 (2 ^ 32-1).',
  whenClientIdIsZero:
    'If the client ID is zero, the decisor ID must be >= 100, and the trading capacity must be DEAL or MTCH.',
  whenClientIdIsNotZero:
    'If the order has a client ID (Value other than 0) then the Trading Capacity must be AOTC or MTCH.',
  whenTradingCapacityIsDeal: 'If the Trading Capacity is DEAL the client ID must be 0.',
  whenExecutorIdIsThree:
    'If executor ID is 3 the Trading Capacity must be AOTC and the Decisor ID must be 0.',
  //Price List columns
  bidVolume: 'Vol.',
  bidPrice: 'Bid',
  askPrice: 'Ask',
  askVolume: 'Vol.',
  lastTrade: 'Last Trade',

  FUTURE: 'Future',

  selected: 'Selected',

  //accountGroup
  accountOperationAccountLabel: 'Oper. Account',
  accountGroupLabel: 'Accounts group',
  accountGroups: 'Accounts groups',
  accountGroupName: 'Accounts Group Name',
  selectAccount: 'Select Account',
  none: 'None',
  member: 'Member',
  market: 'Market',
  client: 'Client',
  accounts: 'Accounts',
  accountId: 'Account ID',
  fixId: 'FIX ID',

  validateAccountGroup_title: 'There are validation errors',
  validateAccountGroup_NotEmptyName: 'Account group name is required.',
  validateAccountGroup_NotEmptyAccounts: 'At least one account is required.',

  hedgingSim: 'Hedging Sim',
  hedgingSimUrl: 'https://www.meff.es/ing/Financial-Derivatives/FX-Currency-Hedging-Simulator',

  marketDepthUrl: 'https://www.meff.es/xRollingFx/Widget?lang=en',

  bm: 'Bm',

  //ModelMessageNotification
  alert: 'Alert',
  maxGuaranteeOrderRejected: account =>
    `Order rejected on account "${account}" due to maximum margin consumption.`,
  warning: 'Warning',
  maxThresholdGuaranteeConsumption: account =>
    `Account "${account}" reaching maximum margin threshold.`,
  maxGuaranteeConsumptionOnAccount: account =>
    `Account "${account}" has consumed maximum established margin.`,
  orderHasBeenRejectedOnAccount: account =>
    `An order has been rejected on account "${account}" because if executed it would exceed the maximum established margin. Please contact your administrator.`,
  reachingMaximumGuaranteeThresholdForAccount: account =>
    `Account "${account}" is reaching the maximum margin threshold. In case of doubt please contact your administrator.`,
  reachedMaximumGuaranteeThresholdForAccount: account =>
    `Account "${account}" has consumed the maximum established margin. Please contact your administrator.`,
  details: 'Details',
  currentAccountMargin: margin => `Current account margin: ${margin}€`,
  accountMarginRequired: margin => `Account margin required to process the order: ${margin}€`,
  warningThreshold: threshold => `Warning threshold: ${threshold}€`,
  affectedOperationAccounts: 'Affected Operation Accounts',
  triggeringOrder: 'Triggering order:',
  rejectedOrder: 'Rejected order:',
  operationAccountNotification: account => `Operation account: ${account}`,
  exchangeNotification: exchange => `Exchange: ${exchange}`,
  typeNotification: type => `Type: ${type}`,
  sideNotification: side => `Side: ${side}`,
  instrumentNotification: instrument => `Instrument: ${instrument}`,
  quantityNotification: quantity => `Volume: ${quantity}`,
  accountMaxMargin: margin => `Account max margin: ${margin}€`,

  //MarketDepth
  vol: 'VOL',
  bid: 'BID',
  ask: 'ASK',

  kidBoughtxRollingFuture: 'xRolling® FX Futures Long',
  kidSoldxRollingFuture: 'xRolling® FX Futures Short',
  kidBoughtxRollingFutureUrl:
    'https://www.meff.es/docs/docsSubidos/KIDS_eng/KIDS-DF/KID_xRolling%C2%AE_FX_Futures_Long.pdf',
  kidSoldxRollingFutureUrl:
    'https://www.meff.es/docs/docsSubidos/KIDS_eng/KIDS-DF/KID_xRolling%C2%AE_FX_Futures_Short.pdf',

  externalCustomerId: 'External Customer ID',


  buyCurrency: 'Currency Bought',
  sellCurrency: 'Currency Sold',
  buyQuantity: 'Volume Bought',
  sellQuantity: 'Volume Sold',
}
