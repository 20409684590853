import { over, lensPath, omit, mergeLeft, view, dissocPath } from 'ramda'

//TODO: Renombrar a book?
export const defaultState = { tick: {}, trade: {}, depth: {}, market: {} }

export default (state = defaultState, { type, payload } = {}) => {
  const dataLens = dataPath => {
    const lensPathArray = [
      type.split('_')[2].toLowerCase(),
      payload.exchangeId,
      payload.symbol,
      payload?.accountId || '',
    ]
    if (dataPath) {
      lensPathArray.push(dataPath)
    }
    return lensPath(lensPathArray)
  }
  const currentDataPrice = dataPath => view(dataLens(dataPath), state) || ''

  const updatedDataPayload = () => {
    return {
      ...payload,
      lastAskPrice: currentDataPrice('askPrice'),
      lastBidPrice: currentDataPrice('bidPrice'),
    }
  }

  const updatedDataState = () => {
    //console.error('Removing data', payload)
    //console.error('State', state)
    // const result = over(
    //   lensPath([type.split('_')[2].toLowerCase()]),
    //   //TODO: Actualizar
    //   dissocPath([payload.exchangeId, payload.symbol, payload.accountId || '']),
    //   state
    // )

    const result = dissocPath(
      [type.split('_')[2].toLowerCase(), payload.exchangeId, payload.symbol, payload.account || ''],
      state
    )
    //console.error('New state', result)
    return result
  }

  switch (type) {
    case 'UPDATE_MARKET_TICK_DATA':
    case 'UPDATE_MARKET_TRADE_DATA':
    case 'UPDATE_MARKET_BOOK_DATA':
      // return over(dataLens(), mergeLeft(updatedDataPayload()), state)
      return over(dataLens(), mergeLeft(payload), state)
    case 'REMOVE_MARKET_TICK_DATA':
    case 'REMOVE_MARKET_TRADE_DATA':
    case 'REMOVE_MARKET_BOOK_DATA':
      return updatedDataState()
    // case 'UPDATE_MARKET_BOOK_DATA':
    //   if (payload.book) {
    //     const book = payload.book.reduce(
    //       (acc, b) => ({
    //         ...acc,
    //         [b.depth]: {
    //           ...b,
    //           lastAskPrice:
    //             state?.depth[`${payload.symbol}:${payload.exchangeId}`]?.[b.depth]?.askPrice || '',
    //           lastBidPrice:
    //             state?.depth[`${payload.symbol}:${payload.exchangeId}`]?.[b.depth]?.bidPrice || '',
    //         },
    //       }),
    //       {}
    //     )
    //     return {
    //       ...state,
    //       market: {
    //         ...state.market,
    //         [`${payload.symbol}:${payload.exchangeId}`]: payload.market,
    //       },
    //       depth: {
    //         ...state.depth,
    //         [`${payload.symbol}:${payload.exchangeId}`]: { ...book },
    //       },
    //     }
    //   } else {
    //     return state
    //   }
    // case 'REMOVE_MARKET_BOOK_DATA':
    //   const updateData = { ...state.depth }
    //   delete updateData[`${payload.symbol}:${payload.exchangeId}`]
    //   return {
    //     ...state,
    //     depth: {
    //       ...updateData,
    //     },
    //   }
    case 'MARKET_SOCKET_CONNECTED':
      return { ...state, connected: true }
    case 'CHANGE_CURRENT_ACC':
      return { ...state, connected: true }
    default:
      return state
  }
}
