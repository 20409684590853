/** @jsx jsx */
import { jsx } from 'theme-ui'
import * as React from 'react'
import { NumericFormat, numericFormatter } from 'react-number-format';

export const FormattedPositiveInteger = props => {
  return <FormattedInteger {...props} allowNegative={false} />
}

export const FormattedInteger = props => {
  return <FormattedNumberText {...props} decimalScale={0} />
}

export const FormattedCurrency = props => {
  return (
    <FormattedNumberText
      {...props}
      suffix={` ${props.currency}`}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  )
}

export const FormattedNumberText = props => {
  return <FormattedNumber {...props} displayType={'text'} />
}

export const FormattedNumberInput = props => {
  const { inputRef, onChange = () => { }, decimals = 0, allowNegative = false, ...other } = props

  return (
    <FormattedNumber
      {...other}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      allowNegative={allowNegative}
      decimalScale={decimals}
      displayType={'input'}
    />
  )
}

const FormattedNumber = props => {
  const { thousandSeparator = ' ', prefix = '', ...otherProps } = props
  return (
    <NumericFormat
      {...otherProps}
      thousandSeparator={thousandSeparator}
      isNumericString
      prefix={prefix}
    />
  )
}

export const stringFormatted = (value) => {
  return value !== undefined ? numericFormatter(value + '', { thousandSeparator: ' ', prefix: '' }) : ''
}

export default FormattedNumberText
